import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import { Image } from 'components/Shared/Image'


import styled from "styled-components"
const Wrapper = styled.div`
  --gap: 0.5rem;
  ${p => p.theme.fullbleed}
  --column-count: 2;
  ${ p => p.theme.mq.sm`
    --column-count: 3;
    `}
  ${ p => p.theme.mq.md`
    --column-count: 4;
    `}
  column-count: var(--column-count);
  column-gap: var(--gap);
  width: 100%;
  margin: var(--gap) 0;

  .gatsby-image-wrapper {
    --grid-template-rows: 1fr auto;
    ${p => p.theme.grid}
    ${p => p.theme.fullbleed}
    break-inside: avoid;
    margin-bottom: 0.5rem;
  }

`
const Gallery = ({data}) => {
  const {seo, images: {nodes: images} } = data
  const {title, description} = seo.frontmatter.gallery
  
  
  return (
    <PageContent>
      <Seo title={title} description={description} />
      <Wrapper>
        { images.map( (image,i) => <Image image={image.childImageSharp} alt={image.name} key={`img-${i}`} /> )}

      </Wrapper>
    </PageContent>
  )
}

export default Gallery

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: {eq: "seo"}}) {
      frontmatter {
        gallery {
          title
          description
        }
      }
    }

    images: allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
    nodes {
      name
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }
  }
  }
`